import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Snatch Grip High Pulls 4×4\\@80% snatch max, starting just below the knee`}</p>
    <p>{`lateral band walks 4x50ft/side`}</p>
    <p>{`then,`}</p>
    <p>{`1 lap run`}</p>
    <p>{`21-deadlifts (225/155)`}</p>
    <p>{`21-burpees over bar`}</p>
    <p>{`1 lap run`}</p>
    <p>{`15-deadlifts`}</p>
    <p>{`15-burpees over bar`}</p>
    <p>{`1 lap run`}</p>
    <p>{`9-deadlifts`}</p>
    <p>{`9-burpees over bar`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      